import request from '@/utils/request'


// 查询会员信息列表
export function listMember(query) {
  return request({
    url: '/platform/member/list',
    method: 'get',
    params: query
  })
}

// 查询会员信息分页
export function pageMember(query) {
  return request({
    url: '/platform/member/page',
    method: 'get',
    params: query
  })
}

// 查询会员信息详细
export function getMember(data) {
  return request({
    url: '/platform/member/detail',
    method: 'get',
    params: data
  })
}

// 新增会员信息
export function addMember(data) {
  return request({
    url: '/platform/member/add',
    method: 'post',
    data: data
  })
}

// 修改会员信息
export function updateMember(data) {
  return request({
    url: '/platform/member/edit',
    method: 'post',
    data: data
  })
}

// 删除会员信息
export function delMember(data) {
  return request({
    url: '/platform/member/delete',
    method: 'post',
    data: data
  })
}
